define('adminui/services/configuration', ['exports', 'ember', 'adminui/adapters/configuration'], function (exports, _ember, _adminuiAdaptersConfiguration) {
    exports['default'] = _ember['default'].Service.extend({

        configurationAdapter: null,

        configuration: null,

        init: function init() {
            this.set('configurationAdapter', _adminuiAdaptersConfiguration['default'].create(_ember['default'].getOwner(this).ownerInjection()));
        },

        loadConfiguration: function loadConfiguration() {
            var _this = this;

            return this.get('configurationAdapter').loadConfiguration().then(function (configuration) {
                _this.set('configuration', configuration);
                return configuration;
            });
        }
    });
});