define('adminui/controllers/dashboard/searchcontent', ['exports', 'ember', 'ember-cli-pagination/computed/paged-array'], function (exports, _ember, _emberCliPaginationComputedPagedArray) {
  exports['default'] = _ember['default'].Controller.extend({
    searchContentService: _ember['default'].inject.service('searchcontent'),
    deleteUndeleteService: _ember['default'].inject.service('deleteUndelete'),
    session: _ember['default'].inject.service(),

    searchKey: 'resource_id',
    searchKeys: [{ key: "resource_id", value: "Resource or Question id" }, { key: "title", value: "Title" }, { key: "creator_id", value: "Creator id" }, { key: "publisher", value: "Publisher" }],
    selectAction: [{ key: "edit", value: "Bulk Edit" }, { key: "publish", value: "Publish" }, { key: "unpublish", value: "Unpublish" }, { key: "delete", value: "Delete" }, { key: "undelete", value: "Undelete" }],
    bulkedit: true,
    contents: [],
    disableSearch: true,
    filter: [{ name: "description" }, { name: "subject" }, { name: "grade" }, { name: "standards" }, { name: "language" }, { name: "license" }, { name: "audience" }, { name: "advertisement level" }, { name: "http_protocol" }, { name: "is_iframe_breaker" }, { name: "is_iframe_breaker_reason" }, { name: "educational use" }, { name: "editorial tag" }],
    tableColumns: [{
      name: 'check',
      label: 'Check',
      order: 1,
      width: 30,
      isHidden: false
    }, {
      name: 'id',
      label: 'ID',
      order: 2,
      isHidden: false
    }, {
      name: 'Title',
      label: 'Title',
      order: 3,
      width: 150,
      isHidden: false
    }, {
      name: 'URL',
      label: 'URL',
      order: 4,
      isHidden: false
    }, {
      name: 'SubFormat',
      label: 'SubFormat',
      order: 5,
      width: 130,
      isHidden: false
    }, {
      name: 'Owner',
      label: 'Owner/Creator ID',
      order: 6,
      width: 150,
      isHidden: false
    }, {
      name: 'PublishStatus',
      label: 'Publish Status',
      order: 7,
      width: 117,
      isHidden: false
    }, {
      name: 'Subject',
      label: 'Subject',
      order: 8,
      width: 100,
      isHidden: true
    }, {
      name: 'GUT',
      label: 'GUT Codes',
      order: 9,
      width: 120,
      isHidden: false
    }, {
      name: 'CopyOwner',
      label: 'Copyright Owner',
      order: 10,
      width: 120,
      isHidden: false
    }, {
      name: 'edit',
      label: 'Edit',
      order: 11,
      width: 80,
      isHidden: false
    }, {
      name: 'description',
      label: 'Description',
      order: 12,
      width: 100,
      isHidden: true
    }, {
      name: 'grade',
      label: 'Grade',
      order: 13,
      width: 100,
      isHidden: true
    }, {
      name: 'standards',
      label: 'Standards',
      order: 14,
      width: 100,
      isHidden: true
    }, {
      name: 'language',
      label: 'Language',
      order: 15,
      width: 100,
      isHidden: true
    }, {
      name: 'license',
      label: 'License',
      order: 16,
      width: 100,
      isHidden: true
    }, {
      name: 'audience',
      label: 'Audience',
      order: 17,
      width: 100,
      isHidden: true
    }, {
      name: 'advertisementLevel',
      label: 'Advertisement level',
      order: 18,
      width: 100,
      isHidden: true
    }, {
      name: 'http_protocol',
      label: 'Http Protocol',
      order: 19,
      width: 100,
      isHidden: true
    }, {
      name: 'is_iframe_breaker',
      label: 'is_iframe_breaker',
      order: 20,
      width: 100,
      isHidden: true
    }, {
      name: 'is_iframe_breaker_reason',
      label: 'is_iframe_breaker_reason',
      order: 21,
      width: 100,
      isHidden: true
    }, {
      name: 'educationalUse',
      label: 'Educational use',
      order: 22,
      width: 100,
      isHidden: true
    }, {
      name: 'editorialTag',
      label: 'Editorial tag',
      order: 23,
      width: 100,
      isHidden: true
    }, {
      name: 'createdAt',
      label: 'Created At',
      order: 24,
      width: 100,
      isHidden: true
    }, {
      name: 'updatedAt',
      label: 'Updated At',
      order: 25,
      width: 100,
      isHidden: true
    }],

    tableData: [],
    tableConfig: {
      enableRowClick: true,
      types: [{ name: 'edit', view: 'edit-button-cell' }]
    },

    values: [],
    columnValue: null,
    columnArray: [],
    options: null,
    previousArray: [],
    newArray: null,
    columnName: null,
    filterValues: [],
    filterValue: null,
    showInput: false,
    filterArray: [],

    actions: {
      searchContent: function searchContent() {
        var _this = this;

        var searchCont = this;
        var keyword = this.get('searchContent');
        var searchkey = this.get('searchKey');
        console.log("searchContent keyword:" + keyword);
        console.log("searchContent searchkey:" + searchkey);
        this.get('searchContentService').searchContent("filterBy", searchkey, "queryText", keyword).then(function (response) {
          //var res = JSON.stringify(response.resources);
          searchCont.set('contents', response.resources);
          var json = _this.get('contents');
          var arr = _ember['default'].A();
          json.forEach(function (data) {
            var obj = {
              check: _ember['default'].String.htmlSafe('<input type="checkbox" name=' + data.id + '>'),
              id: data.id,
              Title: data.title,
              URL: data.url,
              SubFormat: data.content_subformat,
              Owner: data.creator_id,
              PublishStatus: data.publish_status,
              Subject: JSON.stringify(data.subject),
              GUT: data.gut_codes,
              CopyOwner: data.copyright_owner,
              edit: _ember['default'].String.htmlSafe('<button class="btn btn-success" style="height: 23px;margin-top: -2px;padding-top:1px;" >Edit</button>'),
              dataObj: data,
              description: data.description,
              standards: JSON.stringify(data.taxonomy),
              language: data.language,
              license: data.license,
              audience: null,
              advertisementLevel: data.metadata && data.metadata.advertisement_level ? data.metadata.advertisement_level : null,
              http_protocol: data.http_protocol,
              is_iframe_breaker: data.display_guide && data.display_guide.is_frame_breaker ? data.display_guide.is_frame_breaker : 0,
              is_iframe_breaker_reason: data.iframe_breaker_reason,
              educationalUse: data.educational_use,
              editorialTag: JSON.stringify(data.editorial_tags),
              createdAt: data.created_at,
              updatedAt: data.updated_at,
              grade: data.metadata && data.metadata.grade ? data.metadata.grade : null
            };
            arr.pushObject(obj);

            //console.log(this.get('tableData[0]'));
          });
          searchCont.set('tableData', arr);
        }, function (error) {
          console.log("Http communication failed");
          console.log('Http status: ' + error.status + ' Body: [ ' + error.responseText + '] ');
          _this.toast.error("Searching for content failed. Please try again");
          searchCont.set('contents', []);
        });
      },

      navigateDashboard: function navigateDashboard() {
        this.transitionToRoute('dashboard');
      },

      cellClick: function cellClick(row, column) {
        var colName = column.getProperties('name').name;
        if (colName === 'edit') {
          //find the contentItem and call editConntent
          this.send('editContent', row.getProperties('dataObj').dataObj);
          return;
        }
        if (colName === 'select') {
          this.set('cont', row.getProperties('dataObj').dataObj);
          //the row is selected for bulk edit etc
        }
      },

      onSelectFilter: function onSelectFilter(values) {
        this.set('filterValues', values);
        this.set('filterValue', values.name);
        this.set('showInput', true);
      },

      onSelectColumn: function onSelectColumn(values) {
        var self = this;
        this.set('values', values);
        this.set('columnValue', values.name);
        var obj = {
          column: this.get('columnValue')
        };
        this.get('columnArray').pushObject(obj);
        this.get("tableColumns").forEach(function (column) {
          if (column.name === self.get('columnValue')) {
            _ember['default'].set(column, "isHidden", false);
            console.log("Show " + column.name);
          }
        });

        var indexId;
        this.get('options').forEach(function (values, index) {
          if (values.name === self.get('columnValue')) {
            indexId = index;
          }
        });

        var arr = this.get('options');
        var obj = arr[indexId];
        this.get('options').removeObject(obj);
      },

      onRemoveColumn: function onRemoveColumn(value) {
        var indexId;
        this.get('columnArray').forEach(function (values, index) {
          if (values.column === value) {
            indexId = index;
          }
        });

        var arr = this.get('columnArray');
        var obj = arr[indexId];
        this.get('columnArray').removeObject(obj);
        var obj1 = {
          name: value
        };
        this.get('options').pushObject(obj1);

        this.get("tableColumns").forEach(function (column) {
          if (column.name === value) {
            _ember['default'].set(column, "isHidden", true);
            console.log("Hide " + column.name);
          }
        });
      },

      onSubmit: function onSubmit() {

        var obj = {
          filter: this.get('filterValue'),
          keyword: this.get('secondary')
        };

        this.get('filterArray').pushObject(obj);
        this.set('filterValues', []);
        this.set('secondary', '');
        this.set('showInput', false);
      },

      onRemove: function onRemove(value) {
        var indexId;
        this.get('filterArray').forEach(function (values, index) {
          if (values.filter === value) {
            indexId = index;
          }
        });
        console.log("previous:" + this.get('filterArray').length);
        var arr = this.get('filterArray');
        var obj = arr[indexId];
        this.get('filterArray').removeObject(obj);
        console.log("after:" + this.get('filterArray').length);
        //this.set('filterArray',this.get('filterArray'));
        //this.refresh('filterArray');
      },
      //       onSelectEvent(values) {
      //         var self=this;
      //         var prev=this.get('previousArray');
      //         var arrShow=Ember.A();
      //         var arrHide=Ember.A();
      //         var arr=Ember.A();

      //         values.forEach(function(value){
      //           arrShow.push(value.name);
      //           if(prev.indexOf(value.name) == -1){
      //             prev.push(value.name);
      //           }
      //         });
      //         for(var i = 0; i < prev.length; i++){
      //           if(arrShow.indexOf(prev[i]) == -1){
      //             arrHide.push(prev[i]);
      //             prev[i] = "";
      //           }
      //         }
      //         this.get("tableColumns").forEach(function (column) {
      //           if(arrShow.indexOf(column.name) > -1){
      //             Ember.set(column,"isHidden", false);
      //             console.log("Show "+column.name);
      //           }
      //           else if(arrHide.indexOf(column.name) > -1){
      //             Ember.set(column,"isHidden", true);
      //             console.log("Hide "+column.name);
      //           }
      //         });

      //         this.set('values', values);
      //        },

      selectSearchKey: function selectSearchKey(searchKey) {
        this.set('searchKey', searchKey);
      },

      doAction: function doAction(selectedAction) {
        var _this2 = this;

        console.debug("selectedAction:" + selectedAction);
        if (selectedAction === 'edit' || !selectedAction) {
          this.transitionToRoute('dashboard.bulkeditcontent');
          return;
        }
        var content = {};
        this.get('deleteUndeleteService').saveContent(content, content).then(function (response) {
          var res = JSON.stringify(response.resources);
          _this2.toast.info("Data successfully saved!!!");
        }, function (error) {
          console.log("Http communication failed");
          console.log('Http status: ' + error.status + ' Body: [ ' + error.responseText + '] ');
          _this2.toast.error("Oops!! Saving your changes failed. Please try again.");
        });
      },

      editContent: function editContent(contentItem) {
        console.log('editContent ' + contentItem.id);
        this.get('session').set('contentItem', contentItem);
        this.transitionToRoute('dashboard.editcontent');
      }
    },
    validate: (function () {
      if (this.get('searchContent') == '') {
        this.set('disableSearch', true);
      } else {
        this.set('disableSearch', false);
      }
    }).observes('searchContent'),
    queryParams: ["page", "perPage"],
    page: 1,
    perPage: 10,
    pagedContent: (0, _emberCliPaginationComputedPagedArray['default'])('contents', { pageBinding: "page", perPageBinding: "perPage" }),
    totalPagesBinding: "pagedContent.totalPages",

    initialize: (function () {
      var arr = _ember['default'].A();
      this.get("tableColumns").forEach(function (column) {
        if (column.isHidden == true) {
          var obj = {
            name: column.name
          };
          arr.pushObject(obj);
        }
      });
      this.set('options', arr);
    }).on('init')
  });
});