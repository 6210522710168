define('adminui/adapters/impersonate', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Object.extend({

		session: _ember['default'].inject.service(),

		namespace: '/api/nucleus-admin/v1/auth/user/impersonate/',

		authNamespace: '/api/nucleus-auth/v1',

		doImpersonation: function doImpersonation(userId) {
			var adapter = this;
			var namespace = this.get('namespace');
			var url = namespace + userId;
			var options = {
				type: 'POST',
				contentType: 'application/json; charset=utf-8',
				dataType: 'json',
				processData: false,
				headers: adapter.defineHeaders(),
				data: "{}",
				global: false /* Stop global ajaxError event from triggering */
			};
			return _ember['default'].$.ajax(url, options);
		},

		getTokenInCookies: function getTokenInCookies() {
			var namespace = this.get('authNamespace') + '/users/access/token';
			var options = {
				type: 'GET',
				contentType: 'application/json; charset=utf-8;'
			};
			return _ember['default'].$.ajax(namespace, options);
		},

		defineHeaders: function defineHeaders() {
			var sessionData = this.get('session').get('data');
			var token = sessionData.authenticated.token;
			console.log('token is ' + token);
			return {
				'Authorization': 'Token ' + token
			};
		}

	});
});