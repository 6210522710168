define('adminui/adapters/configuration', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Object.extend({
    namespace: '/config',

    loadConfiguration: function loadConfiguration() {
      var adapter = this;
      var namespace = adapter.get('namespace');
      var version = new Date().getTime();
      var configBaseUrl = window.location.origin;
      var hostname = window.location.hostname;
      var basePath = configBaseUrl ? configBaseUrl : '';
      var url = '' + basePath + namespace + '/' + hostname + '.json?v=' + version;
      var options = {
        type: 'GET'
      };
      return _ember['default'].RSVP.hashSettled({
        configuration: _ember['default'].$.ajax(url, options)
      }).then(function (hash) {
        return hash.configuration.value;
      });
    }
  });
});