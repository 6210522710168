define('adminui/mixins/configuration', ['exports', 'ember'], function (exports, _ember) {

  /**
   * Convenience mixin for accesing the app configuration
   *
   * @typedef {Object} ConfigurationMixin
   */
  exports['default'] = _ember['default'].Mixin.create({
    /**
     * @property {Ember.Service} Service to configuration properties
     */
    configurationService: _ember['default'].inject.service('configuration'),

    /**
     * @property {*} application configuration properties
     */
    configuration: _ember['default'].computed.alias('configurationService.configuration')
  });
});