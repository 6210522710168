define('adminui/initializers/instance-initializer', ['exports'], function (exports) {
  exports.initialize = initialize;
  /**
   * Initialize gooru config
   */

  function initialize(application) {
    var configurationService = application.__container__.lookup('service:configuration');
    // Wait until all of the following promises are resolved
    application.deferReadiness();

    configurationService.loadConfiguration().then(function () {
      // Continue the Application boot process, allowing other Initializers to run
      application.advanceReadiness();
    });
  }

  exports['default'] = {
    name: 'gooru-configuration',
    initialize: initialize
  };
});